<template >
  <div>
    <e-charts
      v-if="options" 
      autoresize
      :options="options"
      theme="theme-color"
      auto-resize
      v-on:finished="
        (event) => {
          this.$emit('elementLoaded', event);
        }
      "
    />
  </div>
</template>

<script>
import ECharts from "vue-echarts";


import "echarts";
/*
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/markArea";


import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/gauge"; 
*/
import theme from "./theme.json";
ECharts.registerTheme("theme-color", theme);

//import theme1 from './infographic.js'
//theme1(ECharts)

export default {
  components: {
    ECharts,
  },

      mounted() {
          console.log(this.wrapperProps)
      },  
  props: {
    options: {
      type: Object,
      required: true,
      default: {
      },
    },
    wrapperProps: {
      type: Object,
      default: {}
    }
  },
  methods: {

  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.echarts {
  width: 100% !important;
}
</style>
